import IconChptrElephant from '../assets/chptr-elephant.png';
import {
  ChptrOrderByInput,
  ChptrType,
  ContributionMediaType,
  ContributionType,
  SortOrder,
} from '../gql/generated';

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_CURRENCY = 'usd';

export const GMT_TIMEZONE = 'Etc/GMT';

export const DEFAULT_CONFIG = {
  HOME_HEADER_TEXT: {
    [ChptrType.Memorial]: 'Start your remembrance journey today!',
    [ChptrType.Celebration]: 'Start your journey of celebration today!',
    [ChptrType.Wedding]: 'Celebrate Their Love!',
  },
  HOME_HEADER_SUB_TEXT: {
    [ChptrType.Memorial]:
      'Explore our community-built memorials and craft personalized tributes to honor lives and cherish memories.',
    [ChptrType.Celebration]:
      'Discover and create community-built personalized tributes to commemorate the memories and honor the milestones that shape our lives.',
    [ChptrType.Wedding]:
      'Unveil the beauty of community-built personalized tributes to commemorate their special day and honor the love that binds.',
  },
  HOME_BANNER_VIDEO_URL:
    'https://stream.mux.com/02eGjLWuAN8O8i02u2ho01LN00lL01rm7t1200M9LlF008mZxU.m3u8',
  SEARCH_HEADER_TEXT: 'Obituaries',
  SEARCH_TITLE_TEXT: 'Find a loved one’s memorial',
  SEARCH_PLACEHOLDER_TEXT: 'Find obituary',
  BANNER_BUTTON_TEXT: {
    [ChptrType.Memorial]: 'Create Memorial',
    [ChptrType.Celebration]: 'Create a Tribute',
    [ChptrType.Wedding]: 'Create A Wedding Tribute',
  },
  VIEW_CHPTR_BUTTON_TEXT: {
    [ChptrType.Memorial]: 'View Memorial',
    [ChptrType.Celebration]: 'View Wedding',
    [ChptrType.Wedding]: 'View Celebration',
  },
  PURCHASE_BUTTON_TEXT: 'Purchase Obituary',
  PURCHASE_BUTTON_ABBREVIATED_TEXT: 'Purchase',
  LISTING_TITLE_TEXT: {
    [ChptrType.Memorial]: 'Recent Passings',
    [ChptrType.Celebration]: 'Recent Celebrations',
    [ChptrType.Wedding]: 'Wedding Announcements',
  },
  SEARCH_RESULTS_TITLE_TEXT: 'Passings',
  VIEW_BUTTON_TEXT: 'memories',
  PACKAGE_SUB_TEXT: {
    [ChptrType.Memorial]:
      'Explore our options for memorials ranging from live on-air features to group interviews',
    [ChptrType.Celebration]:
      'Explore our options for personalized and community-built tributes',
    [ChptrType.Wedding]:
      'Explore our options for personalized and community-built tributes',
  },
  CHPTR_CREATE_TEXT: {
    [ChptrType.Memorial]:
      "We're honored you've entrusted us with preserving your memories.",
    [ChptrType.Celebration]:
      "We're honored you've entrusted us with preserving your memories.",
    [ChptrType.Wedding]:
      "We're honored you've entrusted us with preserving your memories.",
  },
  BANNER_ITEMS: {
    MEMORIAL: {
      GLOBAL: {
        DESCRIPTIONS: [
          'What is one moment of theirs you will always remember?',
          'When was the moment you remember becoming a fan of theirs?',
          'Did they inspire you to see yourself or the world differently?',
          'What impact did they make on the world?',
        ],
      },
      NON_GLOBAL: {
        DESCRIPTIONS: [
          'When you close your eyes, where are they? What are they doing?',
          'What is something about them that will always make you laugh?',
          'What will you always remember about them?',
          'When was the last time you saw each other?',
          'If you had one more day with them, what would you do?',
        ],
      },
    },
    WEDDING: {
      GLOBAL: {
        DESCRIPTIONS: [
          'What wisdom can you share with the newlyweds?',
          'What has this couple taught you about love?',
          'Share a photo of you with one of the newlyweds.',
          'How would you describe the couples love in one word?',
          'What is one moment that you caught and no one else saw?',
        ],
      },
      NON_GLOBAL: {
        DESCRIPTIONS: [
          'What wisdom can you share with the newlyweds?',
          'What has this couple taught you about love?',
          'Share a photo of you with one of the newlyweds.',
          'How would you describe the couples love in one word?',
          'What is one moment that you caught and no one else saw?',
        ],
      },
    },
    CELEBRATION: {
      GLOBAL: {
        DESCRIPTIONS: [
          'What has inspired you about this accomplishment?',
          'Share your well wishes with the community.',
          'What is your favorite part of their journey so far?',
          'What words of wisdom can you share?',
          'Share a photo of you both.',
        ],
      },
      NON_GLOBAL: {
        DESCRIPTIONS: [
          'What has inspired you about this accomplishment?',
          'Share your well wishes with the community.',
          'What is your favorite part of their journey so far?',
          'What words of wisdom can you share?',
          'Share a photo of you both.',
        ],
      },
    },
  },
};

export type HomeChptrVisibility =
  | 'Recent'
  | 'Chronological'
  | 'Popular'
  | 'Random';

export const HOME_CHPTR_VISIBILITY_OPTIONS: {
  [key in HomeChptrVisibility]: ChptrOrderByInput;
} = {
  ['Recent']: { createdAt: SortOrder.Desc } as ChptrOrderByInput,
  ['Chronological']: {
    passedAtDay: SortOrder.Desc,
    passedAtMonth: SortOrder.Desc,
    passedAtYear: SortOrder.Desc,
  } as ChptrOrderByInput,
  ['Popular']: { updatedAt: SortOrder.Desc } as ChptrOrderByInput,
  ['Random']: { randomized: SortOrder.Desc } as ChptrOrderByInput,
};

export const DEFAULT_FEATURED_CONTRIBUTION = {
  id: '1',
  mediaType: ContributionMediaType.Video,
  url: 'https://stream.mux.com/SWYvDx2Z9TWnurDLd8eSMDPYG02MH5YE89YY9n5KQ02K8.m3u8',
  thumbnailUrl: IconChptrElephant,
  caption: '',
  type: ContributionType.Memory,
};
