import { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { IoIosSearch } from 'react-icons/io';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import flowerUrl from '../assets/flower.png';
import { ChptrCardSkeleton } from '../components/ChptrCard';
import IntercomMessenger from '../components/IntercomMessenger';
import Button from '../components/ui/Button';
import ChptrPlaceholderProfileImage from '../components/ui/ChptrPlaceholderProfileImage';
import { useUser } from '../contexts/user';
import {
  Chptr,
  ChptrType,
  ChptrVisibility,
  SortOrder,
  useGetRecentChptrsQuery,
  usePlacementQuery,
} from '../gql/generated';
import {
  DEFAULT_CONFIG,
  HOME_CHPTR_VISIBILITY_OPTIONS,
  HomeChptrVisibility,
} from '../modules/Constants';
import FromDateParts from '../modules/FromDateParts';
import LifeSpan from '../modules/LifeSpan';
import { PlacementConfig } from './Home';

// const CHPTR_PLACEMENT_ID = '72aef614-28fe-449c-a671-8b28b2d94ab0';

const Search = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const { placementId } = useParams();

  const [searchParams] = useSearchParams();

  const [user] = useUser();

  useEffect(() => {
    IntercomMessenger(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [searchQuery, setSearchQuery] = useState<string>(
    searchParams.get('q') ?? '',
  );

  const { data: placementData, loading: placementLoading } = usePlacementQuery({
    variables: {
      placementId: placementId ?? '',
    },
    fetchPolicy: 'no-cache',
  });

  const config: PlacementConfig = placementData?.placement?.configuration;

  const {
    loading: chptrLoading,
    data,
    fetchMore,
    refetch,
  } = useGetRecentChptrsQuery({
    variables: {
      query: searchParams.get('q') ?? '',
      visibilities: [ChptrVisibility.Public, ChptrVisibility.GlobalPublic],
      chptrsFirst: 6,
      contributionsFirst: 1,
      placementId: placementId ?? '',
      // isFeatured:
      //   CHPTR_PLACEMENT_ID == placementId && !searchParams.get('q')
      //     ? true
      //     : null,
      ordersBy: config?.homeChptrVisibility
        ? HOME_CHPTR_VISIBILITY_OPTIONS[
            config.homeChptrVisibility as HomeChptrVisibility
          ]
        : {
            createdAt: SortOrder.Desc,
          },
    },
  });

  const loading = placementLoading || chptrLoading;

  return (
    !loading && (
      <div className="mx-auto max-w-[1200px] space-y-6 px-6 py-[20px]">
        <div className="flex justify-between font-heading">
          <p className="text-3xl font-medium md:text-4xl">
            {config?.searchHeaderText ?? DEFAULT_CONFIG.SEARCH_HEADER_TEXT}
          </p>

          <Link
            to={`/${placementId}/purchase`}
            state={{ redirectBack: location.pathname }}
          >
            <Button className="hidden !px-[70px] sm:block">
              {config?.purchaseButtonText ??
                DEFAULT_CONFIG.PURCHASE_BUTTON_TEXT}
            </Button>

            <Button className="!px-[20px] sm:hidden">
              {config?.purchaseButtonAbbreviatedText ??
                DEFAULT_CONFIG.PURCHASE_BUTTON_ABBREVIATED_TEXT}
            </Button>
          </Link>
        </div>

        <div className="hidden flex-col space-y-2 sm:flex">
          <div>
            <div className="w-full rounded-2xl border border-[#d9d9d9] p-4">
              <p className="mb-2 font-heading text-lg text-[#282828]">
                {config?.searchTitleText ?? DEFAULT_CONFIG.SEARCH_TITLE_TEXT}
              </p>

              <IoIosSearch className="absolute ml-3 mt-3 h-5 w-5 text-[#282828]" />

              <input
                placeholder={
                  config?.searchPlaceholderText ??
                  DEFAULT_CONFIG.SEARCH_PLACEHOLDER_TEXT
                }
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="flex h-[45px] w-full rounded-[50px] border-[1px] border-[#E2E2E2] bg-neutral-100 pl-10 pr-1 font-heading focus:outline-none"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && searchQuery.length > 0) {
                    refetch({
                      query: searchQuery,
                      visibilities: [
                        ChptrVisibility.Public,
                        ChptrVisibility.GlobalPublic,
                      ],
                      chptrsFirst: 6,
                      contributionsFirst: 1,
                      placementId: placementId,
                    });

                    navigate(`/${placementId}/search?q=${searchQuery}`);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <Button
          variant="stroke"
          className="w-[93px] !px-2"
          onClick={() => navigate(`/${placementId}`)}
        >
          <BsArrowLeft className="mb-1 mr-1" /> Back
        </Button>

        {searchParams.get('q') &&
          !loading &&
          (data?.placement?.chptrs.totalCount ?? 0) > 0 && (
            <p className="font-heading text-xl leading-[36px] md:text-2xl">
              Showing {data?.placement?.chptrs.totalCount} results for "
              {searchParams.get('q')}"
            </p>
          )}

        {!searchParams.get('q') && (
          <p className="font-heading text-[22px] leading-[36px] md:text-2xl">
            {config?.searchResultsTitleText ??
              DEFAULT_CONFIG.SEARCH_RESULTS_TITLE_TEXT}
          </p>
        )}

        {!loading && (
          <div>
            {data?.placement?.chptrs?.totalCount ?? 0 > 0 ? (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                {data?.placement?.chptrs.edges.map((chptr) => (
                  <SearchCard
                    key={chptr.node.id}
                    chptr={chptr.node as Chptr}
                    viewButtonText={
                      config?.viewButtonText ?? DEFAULT_CONFIG.VIEW_BUTTON_TEXT
                    }
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center space-y-6 text-center">
                <img src={flowerUrl} className="max-w-[200px]" />

                <p className="w-[90vw] truncate font-heading text-[20px] leading-[26px]">
                  No results for
                  <br /> "{searchParams.get('q')}"
                </p>

                <p className="w-[90vw] truncate font-heading text-[20px] leading-[26px]">
                  Want to create a Chptr for
                  <br /> {searchParams.get('q')}?
                </p>

                <Button onClick={() => navigate(`/${placementId}/purchase`)}>
                  Purchase Chptr
                </Button>
              </div>
            )}
          </div>
        )}

        {loading && (
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
            {Array.from({ length: 3 }).map((_, idx) => (
              <ChptrCardSkeleton key={idx.toString()} />
            ))}
          </div>
        )}

        {data?.placement?.chptrs?.pageInfo.hasNextPage && (
          <div className="flex justify-center space-x-4">
            <Button
              variant="stroke"
              className="!px-[50px]"
              onClick={() => {
                fetchMore({
                  variables: {
                    query: searchQuery,
                    after: data?.placement?.chptrs?.pageInfo.endCursor,
                  },
                });
              }}
            >
              See More
            </Button>
          </div>
        )}
      </div>
    )
  );
};

const SearchCard = ({
  chptr,
  viewButtonText,
}: {
  chptr: Chptr;
  viewButtonText: string;
}) => {
  const navigate = useNavigate();

  const { placementId } = useParams();

  const bornAt = FromDateParts(chptr.bornAt);
  const passedAt = FromDateParts(chptr.passedAt);
  const lifeSpan = LifeSpan(bornAt, passedAt, chptr?.type);
  const dates = chptr.type === ChptrType.Memorial ? lifeSpan : bornAt;

  return (
    <div className="flex-none justify-center overflow-clip rounded-[20px] border-[1px] border-[#FFC612] bg-white md:w-full">
      <div className="aspect-square w-full overflow-hidden">
        {(chptr.pictureUrl || chptr.webPictureUrl) && (
          <img
            className="h-full w-full object-cover"
            src={chptr.webPictureUrl ? chptr.webPictureUrl : chptr.pictureUrl!}
            alt="Profile Banner Image"
          />
        )}

        {!chptr.pictureUrl && !chptr.webPictureUrl && (
          <ChptrPlaceholderProfileImage
            className="h-full"
            iconWrapperClassName="scale-75"
          />
        )}
      </div>
      <div className="grid grid-flow-row">
        <div className="m-4">
          <p className="font-heading text-xl">
            {chptr.type === ChptrType.Wedding
              ? `${chptr.firstName} & ${chptr.lastName}`
              : `${chptr.firstName} ${chptr.lastName}`}
          </p>
          {dates && <p className="text-neutral-700">{dates}</p>}
        </div>
        <div className="mx-4 !mb-4">
          <button
            className="inline-flex h-12 w-full items-center justify-center rounded-3xl bg-neutral-800 font-heading text-white"
            onClick={() => navigate(`/${placementId}/chptr/${chptr.id}`)}
          >
            View {viewButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Search;
