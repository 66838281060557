import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'unfonts.css';

import Root from './Root.tsx';
import './index.css';
import ChptrMain from './screens/ChptrMain.tsx';
import EventSearch from './screens/EventSearch.tsx';
import Home from './screens/Home.tsx';
import NotFound from './screens/NotFound.tsx';
import Purchase from './screens/Purchase.tsx';
import PurchaseComplete from './screens/PurchaseComplete.tsx';
import Search from './screens/Search.tsx';
import SentryError from './screens/Sentry.tsx';
import ContributionComments from './screens/modals/ContributionComments.tsx';

Sentry.init({
  dsn: 'https://e766fc8a07f4fb34fd1cae359b9c1c83@o4504522349346816.ingest.us.sentry.io/4508127335546880',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: '/',
    id: 'NOT_FOUND',
    element: <NotFound />,
  },
  {
    path: '/sentry-error',
    element: <SentryError />,
  },
  {
    path: '/',
    id: 'ROOT',
    element: <Root />,
    children: [
      {
        id: 'HOME',
        path: ':placementId',
        element: <Home />,
      },
      {
        id: 'PURCHASE',
        path: ':placementId/purchase',
        element: <Purchase />,
      },
      {
        path: ':placementId/order/:orderId',
        element: <PurchaseComplete />,
      },
      {
        path: ':placementId/chptr/:id',
        element: <ChptrMain />,
      },
      {
        path: ':placementId/search',
        element: <Search />,
      },
      {
        path: ':placementId/event-search',
        element: <EventSearch />,
      },
      {
        path: ':placementId/modals/contributions/:contributionId/comments',
        element: <ContributionComments />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
