import classNames from 'classnames';
import { useState } from 'react';
import { useInterval } from 'usehooks-ts';

import { Chptr, ChptrType, ContributionMediaType } from '../gql/generated';
import FromDateParts from '../modules/FromDateParts';
import LifeSpan from '../modules/LifeSpan';
import ChptrPlaceholderProfileImage from './ui/ChptrPlaceholderProfileImage';

interface ChptrCardParams {
  chptr: Chptr;
  onClick: () => void;
}

const ChptrCard = ({ chptr, onClick }: ChptrCardParams) => {
  const [imageUrl, setImageUrl] = useState(
    chptr.webPictureUrl ? chptr.webPictureUrl : chptr.pictureUrl!,
  );
  const [imagesInd, setImagesInd] = useState(0);
  const [imagesCycle, setImagesCycle] = useState<boolean>(false);

  const bornAt = FromDateParts(chptr.bornAt);
  const passedAt = FromDateParts(chptr.passedAt);
  const lifeSpan = LifeSpan(bornAt, passedAt, chptr.type);
  const dates = chptr.type === ChptrType.Memorial ? lifeSpan : bornAt;
  const hasHeaderImage = chptr && (chptr.pictureUrl || chptr.webPictureUrl);
  const images = chptr.contributions.edges
    .filter(
      (c) =>
        c.node.mediaType === ContributionMediaType.Image ||
        c.node.mediaType === ContributionMediaType.Video,
    )
    .map((c) => {
      return c.node.mediaType === ContributionMediaType.Image
        ? c.node.url
        : c.node.thumbnailUrl;
    })
    .slice(0, 10);
  const imagesCount = images.length;

  useInterval(
    () => {
      if (imagesInd === imagesCount) {
        setImageUrl(
          chptr.webPictureUrl ? chptr.webPictureUrl : chptr.pictureUrl!,
        );
        setImagesInd(0);
        return;
      }
      setImageUrl(images[imagesInd] ?? '');
      setImagesInd(imagesInd + 1);
      return;
    },
    // Delay in milliseconds or null to stop it
    imagesCycle ? 2000 : null,
  );

  return (
    <div
      key={chptr.id}
      className="relative h-[473px] flex-none cursor-pointer justify-center overflow-clip rounded-[10px] border border-[#D9D9D9] bg-white hover:border-[3px] hover:border-[#FFC612] sm:w-[48%] md:w-full"
      onClick={onClick}
      onMouseEnter={() => setImagesCycle(imagesCount ? true : false)}
      onMouseLeave={() => setImagesCycle(false)}
    >
      <div className="relative h-full w-full object-cover hover:scale-105">
        {hasHeaderImage && (
          <>
            <img
              className="h-full w-full bg-neutral-300 object-cover"
              src={imageUrl}
              alt="Profile Banner Image"
            />

            <div className="absolute top-0 h-full w-full bg-gradient-to-t from-black to-25%" />
          </>
        )}

        {!hasHeaderImage && (
          <div className="h-full w-full">
            <ChptrPlaceholderProfileImage
              className="h-full"
              iconWrapperClassName="scale-75"
            />
          </div>
        )}
      </div>
      <div
        className={classNames('absolute bottom-5 w-full text-center', {
          'text-neutral-900': !hasHeaderImage,
          'text-white': hasHeaderImage,
        })}
      >
        {chptr && (
          <p className="font-heading text-2xl font-medium">
            {chptr.type === ChptrType.Wedding
              ? `${chptr.firstName} & ${chptr.lastName}`
              : `${chptr.firstName} ${chptr.lastName}`}
          </p>
        )}
        {!chptr && (
          <div className="mx-auto h-8 w-1/2 animate-pulse rounded-full bg-neutral-300" />
        )}
        {dates && <p className="text-lg tracking-wide">{dates}</p>}
        {chptr.type === ChptrType.Wedding &&
        (chptr.property || (chptr.events && chptr.events[0]?.property)) ? (
          <>
            <p className="text-lg tracking-wide">
              {chptr.property
                ? chptr.property.name
                : chptr.events[0].property?.name}
            </p>
          </>
        ) : (
          <>
            {(chptr.location ||
              (chptr.events && chptr.events[0]?.location)) && (
              <p className="text-lg tracking-wide">
                {chptr.location || chptr.events[0].location}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ChptrCard;

export const ChptrCardSkeleton = () => (
  <div className="flex animate-pulse flex-col rounded-xl border-2 border-neutral-300">
    <div className="h-28 w-full bg-neutral-300" />

    <div className="mx-4 mt-8 h-8 rounded-sm bg-neutral-300" />
    <div className="mx-4 mt-1 h-4 rounded-sm bg-neutral-300" />

    <div className="mx-4 mt-6 h-32 rounded-sm bg-neutral-300" />

    <div className="mx-4 my-6 h-10 rounded-sm bg-neutral-300" />
  </div>
);
