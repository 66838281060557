import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import Linkify from 'linkify-react';
import { useEffect, useState } from 'react';

import { ChptrType } from '../gql/generated';
import useGoogleMaps from '../hooks/useGoogleMaps';
import { parseTime } from '../lib/utils';
import AddressLink from './ui/AddressLink';

type EventPreviewDetailsProps = {
  chptrType: ChptrType;
  firstName: string;
  lastName: string;
  dates: string | number | null | undefined;
  location: string;
  locationPlaceId: string;
  chptrLocation: string | undefined;
  startDate: string;
  startTime: string;
  endTime: string;
  visitStartDate: string;
  visitStartTime: string;
  visitEndTime: string;
  visitLocation: string;
  visitLocationPlaceId: string;
  notes?: string;
  viewOnly?: boolean;
};

const EventPreviewDetails = ({
  chptrType,
  firstName,
  lastName,
  dates,
  location,
  locationPlaceId,
  chptrLocation,
  startDate,
  startTime,
  endTime,
  visitStartDate,
  visitStartTime,
  visitEndTime,
  visitLocation,
  visitLocationPlaceId,
  notes,
  viewOnly,
}: EventPreviewDetailsProps) => {
  const { fetchPlace, isAPIReady } = useGoogleMaps();

  const [locationAddress, setLocationAddress] = useState<string | null>(null);
  const [visitLocationAddress, setVisitLocationAddress] = useState<
    string | null
  >(null);

  useEffect(() => {
    if (locationPlaceId) {
      fetchPlace(locationPlaceId).then((place) => {
        if (!place?.place_id || !place?.formatted_address) return;
        setLocationAddress(place.formatted_address);
      });
      return;
    }

    setLocationAddress(null);
  }, [locationPlaceId, fetchPlace, isAPIReady]);

  useEffect(() => {
    if (visitLocationPlaceId) {
      fetchPlace(visitLocationPlaceId).then((place) => {
        if (!place?.place_id || !place?.formatted_address) return;
        setVisitLocationAddress(place.formatted_address);
      });
      return;
    }

    setVisitLocationAddress(null);
  }, [visitLocationPlaceId, fetchPlace, isAPIReady]);

  return (
    <div className="space-y-1 p-4">
      <p className="font-heading text-2xl font-medium text-[#282828]">
        {chptrType === ChptrType.Wedding
          ? `${firstName} & ${lastName}`
          : `${firstName} ${lastName}`}
      </p>

      {dates && <p className="text-lg tracking-wide text-[#666666]">{dates}</p>}
      {(chptrLocation || location) && (
        <p className="text-lg tracking-wide text-[#666666]">
          {chptrLocation || location}
        </p>
      )}

      <p className="pt-6 font-heading font-medium text-[#282828]">
        {chptrType === ChptrType.Memorial
          ? `Remembering ${firstName}:`
          : chptrType === ChptrType.Wedding
            ? `Celebrating ${firstName} & ${lastName}:`
            : `Celebrating ${firstName}:`}
      </p>

      <p className="font-normal text-[#282828]">
        {`Join us in celebrating ${chptrType !== ChptrType.Memorial ? 'with' : ''} ${
          chptrType === ChptrType.Wedding
            ? `${firstName} & ${lastName}`
            : `${firstName} ${lastName}`
        }
        ${chptrType === ChptrType.Memorial ? '’s life' : ''}
        ${startDate ? `on ${format(parseISO(startDate), 'EEEE, MMMM d, yyyy')}` : !viewOnly ? 'on <Date>' : ''} 
        ${startTime ? `${endTime ? 'from' : 'at'} ${format(parseTime(startTime), 'h:mm a')}` : !viewOnly ? 'from <Start>' : ''} 
        ${endTime ? `to ${format(parseTime(endTime), 'h:mm a')}` : !viewOnly && startTime ? 'to <End>' : ''}
        ${startTime || endTime ? ` ${format(parseTime(startTime || endTime), 'z')}` : ''}`}
        {location ? ` at ${location}` : !viewOnly ? 'at <Location>' : ''}
        {locationAddress ? (
          <>
            <span> at </span>
            <AddressLink address={locationAddress} />.
          </>
        ) : (
          '.'
        )}
      </p>

      {visitStartDate && visitStartTime && (
        <p className="font-normal text-[#282828]">
          {`${
            chptrType === ChptrType.Wedding ? 'Reception' : 'Visitation'
          } is ${visitEndTime ? 'from' : 'at'} ${format(parseISO(visitStartDate), 'EEEE, MMMM d, yyyy')} at ${format(parseTime(visitStartTime), 'h:mm a')}
              ${visitEndTime ? `to ${format(parseTime(visitEndTime), 'h:mm a')}` : !viewOnly ? 'to <End>' : ''}
              ${visitStartTime || visitEndTime ? ` ${format(parseTime(visitStartTime || visitEndTime), 'z')}` : ''}`}
          {visitLocation
            ? ` at ${visitLocation}`
            : !viewOnly
              ? 'at <Location>'
              : ''}
          {visitLocationAddress ? (
            <>
              <span> at </span>
              <AddressLink address={visitLocationAddress} />.
            </>
          ) : (
            '.'
          )}
        </p>
      )}

      <p className="overflow-hidden text-ellipsis whitespace-pre font-normal text-[#282828]">
        <Linkify
          options={{
            className: 'text-cyan-900',
            target: '_blank',
          }}
        >
          {notes}
        </Linkify>
      </p>

      <Map
        style={{
          width: '100%',
          height: '150px',
          marginTop: '8px',
          display: 'none', // Note that the map is hidden. We need this map instance for the google api to work, so we just hide it.
        }}
        defaultZoom={3}
        defaultCenter={{ lat: 0, lng: 0 }}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
      />
    </div>
  );
};

const EventPreviewDetailsWrapper = (props: EventPreviewDetailsProps) => {
  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY}>
      <EventPreviewDetails {...props} />
    </APIProvider>
  );
};

export default EventPreviewDetailsWrapper;
