import { zodResolver } from '@hookform/resolvers/zod';
import cn from 'classnames';
import Linkify from 'linkify-react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { IoMdClose } from 'react-icons/io';
import { z } from 'zod';

import {
  ChptrType,
  GetChptrContributionsQuery,
  useUpdateChptrMutation,
} from '../gql/generated';
import Button from './ui/Button';
import FormTextarea from './ui/FormTextarea';
import Modal from './ui/Modal';

const AboutSchema = z.object({
  about: z.string(),
});

export type AboutSchemaType = z.infer<typeof AboutSchema>;

const ChptrDetailsModal = ({
  isOpen,
  mode,
  onClose,
  top,
  chptr,
}: {
  isOpen: boolean;
  mode: 'edit' | 'view';
  onClose: () => void;
  top?: number;
  chptr: GetChptrContributionsQuery['chptr'];
}) => {
  const [updateChptr, { loading }] = useUpdateChptrMutation({});

  const methods = useForm<AboutSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(AboutSchema),
    defaultValues: {
      about: chptr?.about || '',
    },
  });

  const title = chptr?.type === ChptrType.Memorial ? 'Obituary' : 'Details';

  const onSubmit: SubmitHandler<AboutSchemaType> = async ({ about }) => {
    try {
      await updateChptr({
        variables: {
          input: {
            id: chptr.id,
            about,
          },
        },
      });
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      enableMobile
      isOpen={isOpen}
      onClose={onClose}
      containerClassName={cn('w-full max-w-[800px] sm:mx-[5%]')}
      style={{
        top: top || '10%',
      }}
    >
      <div className="space-y-4">
        <div className="flex flex-row items-center justify-between">
          <div className="w-12">{null}</div>

          <p className="font-heading text-xl text-neutral-600">
            {mode === 'edit' && 'Edit '}
            {title}
          </p>

          <div className="flex w-12 justify-end">
            <IoMdClose size={24} className="cursor-pointer" onClick={onClose} />
          </div>
        </div>
        <FormProvider {...methods}>
          <form className="space-y-4" onSubmit={methods.handleSubmit(onSubmit)}>
            {mode === 'view' && (
              <div className="min-h-[322px] rounded-lg border border-neutral-400 bg-neutral-100 p-4 text-neutral-700">
                <p className="overflow-hidden text-ellipsis whitespace-pre text-wrap font-heading font-normal text-[#282828]">
                  <Linkify
                    options={{
                      className: 'text-cyan-900',
                      target: '_blank',
                    }}
                  >
                    {chptr?.about || ''}
                  </Linkify>
                </p>
              </div>
            )}
            {mode === 'edit' && (
              <>
                <FormTextarea name="about" rows={12} />
                <div className="flex justify-center">
                  <Button type="submit" disabled={loading} loading={loading}>
                    Save
                  </Button>
                </div>
              </>
            )}
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default ChptrDetailsModal;
