import classNames from 'classnames';
import { useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { IoLogoYoutube } from 'react-icons/io';
import Markdown from 'react-markdown';
import ReactPlayer from 'react-player/lazy';

import LoadingFlower from '../assets/loading-flower.png';
import { useAnalytics } from '../contexts/analytics.tsx';
import { usePurchase } from '../contexts/purchase.tsx';
import { useUser } from '../contexts/user.tsx';
import {
  ProductMediaType,
  PurchaseOrderFragment,
  useGetUserOrderLazyQuery,
  useRemoveOrderPromotionMutation,
  useUpdateOrderMutation,
} from '../gql/generated.tsx';
import { convertToCurrency } from '../lib/utils.ts';
import Button from './ui/Button.tsx';

export default function PurchasePackageCard({
  id,
  title,
  recommended,
  amount,
  description,
  currency,
  mediaUrl,
  mediaType,
}: {
  id: string;
  title: string;
  recommended: boolean;
  amount: number;
  description: string;
  currency: string;
  mediaUrl?: string | null;
  mediaType?: string | null;
}) {
  const { show, selectedPackageId, savePackage, createdOrderId } =
    usePurchase();

  const [user] = useUser();

  const { segmentTrack } = useAnalytics();

  const { showBoundary } = useErrorBoundary();

  const [loading, setLoading] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);

  const [getUserOrder] = useGetUserOrderLazyQuery();

  const [updateOrder] = useUpdateOrderMutation();

  const [removeOrderPromotion] = useRemoveOrderPromotionMutation({});

  const removeOrderPromotions = async (order: PurchaseOrderFragment) => {
    for (const promotion of order.promotions) {
      await removeOrderPromotion({
        variables: {
          input: {
            code: promotion.code,
            orderId: order.id,
          },
        },
      });
    }
  };

  const selectPackage = async () => {
    setLoading(true);
    savePackage(id);
    segmentTrack('Package Selected', { id, name: title, price: amount });

    if (createdOrderId && user?.token && user.isContributionUser) {
      try {
        const { data } = await getUserOrder({
          variables: {
            userId: user.id,
            orderId: createdOrderId,
          },
        });

        const products = new Set([
          ...(data?.user?.order?.products
            .filter((o) => o.type === 'ADDON')
            .map((o) => o.id) ?? []),
          id,
        ]);

        await updateOrder({
          variables: {
            input: {
              id: createdOrderId,
              productIds: [...products],
            },
          },
        });

        if (id !== selectedPackageId && data?.user?.order) {
          await removeOrderPromotions(data?.user?.order);
        }
      } catch (e) {
        return showBoundary(e);
      }
    }

    setLoading(false);
    show('DETAILS');
  };

  return (
    <div
      key={id}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={classNames(
        'relative flex flex-col justify-between rounded-[10px] border-2 bg-white p-6 drop-shadow-md duration-150 ease-in hover:border-2 hover:border-[#2E5E6B] hover:drop-shadow-xl',
        recommended ? 'border-[#FFC612]' : 'border-[#D9D9D9]',
      )}
    >
      <p
        className={classNames(
          'absolute left-0 right-0 top-4 mx-auto flex h-8 w-32 items-center justify-center rounded-full bg-[#FFC612] px-2 text-[14px] font-medium text-[#282828]',
          { hidden: !recommended },
        )}
      >
        Most Popular
      </p>
      <div className="mt-6 flex flex-col items-center justify-between gap-4">
        {mediaType === ProductMediaType.Image && (
          <img
            className="h-[120px] w-[40%] object-cover sm:w-[90%]"
            src={mediaUrl || LoadingFlower}
          ></img>
        )}
        {mediaType === ProductMediaType.Video && (
          <div className="h-[120px] w-[40%] object-cover sm:w-[90%]">
            <ReactPlayer
              playing
              muted
              playsinline
              width={'100%'}
              height={'100%'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                borderRadius: 20,
                overflow: 'hidden',
                backgroundColor: 'black',
              }}
              playIcon={<IoLogoYoutube size={50} color="#e5e5e5" />}
              url={mediaUrl ?? ''}
              controls
            />
          </div>
        )}
        <p className="text-center font-heading text-[18px] font-medium leading-[25px] text-[#282828] md:text-[20px]">
          {title}
        </p>
      </div>
      <p className="mt-4 text-center text-[32px] font-semibold leading-[45px] text-[#282828] sm:text-[24px] md:text-[32px]">
        {convertToCurrency(amount, currency)}
      </p>
      <div className="mt-4 grid grid-cols-1 items-center">
        <Markdown
          className="space-y-2 text-center text-[16px] font-normal leading-[22px] text-[#282828]"
          components={{
            p(props) {
              return <p className="text-[16px]">{props.children}</p>;
            },
            ul(props) {
              return (
                <ul className="ml-4 list-inside list-disc text-[18px]">
                  {props.children}
                </ul>
              );
            },
          }}
        >
          {description}
        </Markdown>
      </div>
      <div className="mt-4 flex justify-center pt-6 sm:mt-auto">
        <Button
          className={classNames('w-4/5 sm:w-7/12', {
            'bg-[#1C424C]': hover && selectedPackageId !== id,
          })}
          loading={loading}
          disabled={loading}
          variant={selectedPackageId === id ? 'stroke' : 'blue'}
          onClick={selectPackage}
        >
          {`${selectedPackageId === id ? 'Selected' : 'Select'}`}
        </Button>
      </div>
    </div>
  );
}
