import classNames from 'classnames';
import { isValid, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { BsCalendar4Event } from 'react-icons/bs';
import { FaRegClock } from 'react-icons/fa6';

import LoadingFlower from '../assets/loading-flower.png';
import { useApplication } from '../contexts/application';
import { ChptrType, PlacementEventFragment } from '../gql/generated';
import FromDateParts from '../modules/FromDateParts';
import LifeSpan from '../modules/LifeSpan';
import EventModal from './EventModal';
import ChptrPlaceholderProfileImage from './ui/ChptrPlaceholderProfileImage';

const EventCard = ({ event }: { event: PlacementEventFragment }) => {
  const { getScrollPosition } = useApplication();

  const [tileHover, setTileHover] = useState<boolean>(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState<boolean>(false);
  const [scroll, setScroll] = useState<number>(getScrollPosition());

  const chptr = event?.chptr;

  const hasHeaderImage = chptr?.pictureUrl || chptr?.webPictureUrl;
  const bornAt = FromDateParts(chptr?.bornAt, true);
  const passedAt = FromDateParts(chptr?.passedAt, true);
  const lifeSpan = LifeSpan(bornAt, passedAt, chptr?.type);
  const dates = chptr?.type === ChptrType.Memorial ? lifeSpan : bornAt;

  const eventDateTime = FormatEventDateTime(
    event.startAt ?? '',
    event.endAt ?? '',
  );

  const visitationDateTime = FormatEventDateTime(
    event.visitStartAt ?? '',
    event.visitEndAt ?? '',
  );

  useEffect(() => {
    setScroll(getScrollPosition());
  }, [isEventModalOpen, getScrollPosition]);

  return (
    <div
      className="flex w-fit flex-col overflow-clip rounded-[10px] border border-[#d9d9d9] shadow-md hover:cursor-pointer hover:border-[#FFC612] hover:shadow-lg md:grid md:grid-cols-5"
      onClick={() => chptr && setIsEventModalOpen(true)}
      onMouseOver={() => setTileHover(true)}
      onMouseLeave={() => setTileHover(false)}
    >
      <div
        className={classNames(
          'relative aspect-square w-full overflow-hidden md:col-span-2 md:h-[280px]',
          {
            'scale-105': tileHover,
          },
        )}
      >
        {hasHeaderImage && (
          <img
            className="h-full w-full bg-neutral-300 object-cover"
            src={chptr.webPictureUrl ? chptr.webPictureUrl : chptr.pictureUrl!}
            alt="Profile Banner Image"
          />
        )}

        {!hasHeaderImage && (
          <div className="h-full w-full">
            <ChptrPlaceholderProfileImage
              className="h-full px-24"
              iconWrapperClassName="scale-75"
            />
          </div>
        )}
      </div>

      <div className="bg-[#f9fafb] px-6 py-4 text-center md:col-span-3 md:text-left">
        <div className="space-y-1 pb-6">
          {chptr && (
            <p className="font-heading text-xl font-medium text-[#282828]">
              {chptr.type === ChptrType.Wedding
                ? `${chptr.firstName} & ${chptr.lastName}`
                : `${chptr.firstName} ${chptr.lastName}`}
            </p>
          )}

          {dates && (
            <p className="text-lg tracking-wide text-[#666666]">{dates}</p>
          )}
        </div>

        <div className="flex gap-2 pb-6">
          {visitationDateTime && (
            <div className="grow space-y-2 rounded-lg border border-[#D9D9D9] bg-white/80 p-2">
              <p className="text-[14px] text-[#282828]">
                {chptr?.type === ChptrType.Wedding ? 'Reception' : 'Visitation'}
              </p>
              <div className="flex items-center gap-2">
                <div className="h-5 w-5">
                  <BsCalendar4Event strokeWidth={0.5} size={18} />
                </div>
                <p className="text-[16px] font-normal text-[#282828]">
                  {visitationDateTime.date}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <div className="h-5 w-5">
                  <FaRegClock size={18} />
                </div>
                <p className="text-[16px] font-normal text-[#282828]">
                  {visitationDateTime.time}
                </p>
              </div>
            </div>
          )}
          {eventDateTime && (
            <div className="grow space-y-3 rounded-lg border border-[#D9D9D9] bg-white/80 p-2">
              <p className="text-[14px] text-[#282828]">
                {chptr?.type === ChptrType.Wedding ? 'Ceremony' : 'Service'}{' '}
              </p>
              <div className="flex items-center gap-2">
                <div className="h-5 w-5">
                  <BsCalendar4Event strokeWidth={0.5} size={18} />
                </div>
                <p className="text-[16px] font-normal text-[#282828]">
                  {eventDateTime.date}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <div className="h-5 w-5">
                  <FaRegClock size={18} />
                </div>
                <p className="text-[16px] font-normal text-[#282828]">
                  {eventDateTime.time}
                </p>
              </div>
            </div>
          )}
        </div>
        {event.property && (
          <div className="gap-2">
            <img
              className="mx-auto h-[22px] w-[22px]"
              src={event.property?.imageUrl ?? LoadingFlower}
            />
            <p className="mx-auto w-fit">
              {event.property?.name ??
                `No associated ${chptr?.type === ChptrType.Memorial ? 'funeral home' : 'partner'}`}
            </p>
          </div>
        )}
      </div>
      {isEventModalOpen && chptr?.id && (
        <EventModal
          chptrId={chptr.id}
          isOpen={isEventModalOpen}
          onClose={() => setIsEventModalOpen(false)}
          top={scroll + 100}
          showNav
          viewOnly
        />
      )}
    </div>
  );
};

export const FormatEventDateTime = (start: string, end: string) => {
  const startDateTime = parseISO(start);
  const endDateTime = parseISO(end);

  if (isValid(startDateTime) && isValid(endDateTime)) {
    const formattedDate = format(startDateTime, 'EEEE, MMMM d, yyyy');
    const formattedStartTime = format(startDateTime, 'p');
    const formattedEndTime = format(endDateTime, 'p z');
    return {
      date: formattedDate,
      time: `${formattedStartTime} - ${formattedEndTime}`,
    };
  }

  if (isValid(startDateTime) && !isValid(endDateTime)) {
    const formattedDate = format(startDateTime, 'EEEE, MMMM d, yyyy');
    const formattedStartTime = format(startDateTime, 'p z');
    return { date: formattedDate, time: `${formattedStartTime}` };
  }

  return '';
};

export default EventCard;
